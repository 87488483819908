import React from 'react';
import styled from '@emotion/styled';

import { Table } from '../../../components/table';
import { Button } from '../../../components';

export const ButtonBottom = styled.div`
  height: 15px;
`;
export const TableWrapperContainer = styled.div`
  width: 100%;
  @media print {
    * {
      display: none;
    }
  }
`;

export default function TableChart({ config }: any) {
  const generateCSV = (messageData: any) => {
    const csvData = [];
    const ids = messageData.columns.map((column: { dataIndex: any }) => column.dataIndex);
    csvData.push(messageData.columns.map((column: { title: any }) => column.title));
    csvData.push(
      ...messageData.data.map((element: any) => {
        return ids.map((id: string | number) => element[id]);
      })
    );

    return csvData.map(row => row.join(',')).join('\n');
  };
  const downloadCsv = () => {
    const csv = generateCSV(config);

    const cvsBlob = new Blob([csv], { type: 'text/csv' });
    const fileURL = URL.createObjectURL(cvsBlob);
    const downloadLink = document.createElement('a');

    downloadLink.download = `report.csv`;
    downloadLink.href = fileURL;
    downloadLink.onclick = () => document.body.removeChild(downloadLink);
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);
    downloadLink.click();
  };

  return (
    <TableWrapperContainer>
      <ButtonBottom />
      <Button type="primary" onClick={downloadCsv}>
        Download CSV
      </Button>
      <ButtonBottom />
      <Table
        dataSource={config.data}
        columns={config.columns}
        rowKey="id"
        tableLayout="fixed"
        pagination={{ pageSize: 50 }}
        scroll={{ x: config.columns.length * 200, y: '65vh' }}
      />
    </TableWrapperContainer>
  );
}
